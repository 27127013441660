<template>
  <md-card class="flat text-center relative">
    <md-card-area>
    <md-card-media>
        <img
        src="https://cdn.pixabay.com/photo/2015/01/08/18/26/write-593333_960_720.jpg"
        alt="People"
        />
    </md-card-media>
    <div class="text-base font-bold text-uppercase mt-2">File name .pdf</div>
    </md-card-area>
    <div class="absolute" style="top: 0; right: 0; z-index: 111">
    <img width="50" src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/i/ce572dc7-f6bc-4d9f-a140-fd183b1c15b7/dcl8rf1-76485ebc-d5dd-4c48-9880-dbe70ce58a53.png" alt="">
    </div>
    <div v-show="rating" class="absolute rounded-tr rounded-br bg-white" style="top: 5px; left: 0; z-index: 111;">
        <md-icon class="text-base text-victoria">star</md-icon>
        <md-icon class="text-base text-victoria">star</md-icon>
        <md-icon class="text-base text-victoria">star</md-icon>
        <md-icon class="text-base text-victoria">star</md-icon>
        <md-icon class="text-base text-victoria">star</md-icon>
    </div>
</md-card>
</template>

<script>
export default {
    props: {
        rating: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>

</style>