<template>
  <div class="p-3">
    <div class="flex justify-between mb-6">
      <SelectComponent class="w-48"/>
      <SearchBox placeholder="search" class="w-56 p-2 m-1" />
    </div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-20 mb-6" v-for="i in 16" :key="i">
         <MaterialCard />
      </div>
    </div>
  </div>
</template>

<script>
import MaterialCard from "@/components/molecule/material/MaterialCard";
import SearchBox from "@/components/atom/SearchBox";
import { SelectComponent } from "@/components";

export default {
  name: "Todo-List",
  components: {
    SearchBox,
    MaterialCard,
    SelectComponent,
  }
};
</script>
